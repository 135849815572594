// rollover
var preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll
var targetIdHold = jQuery(this.hash);

jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t = t / d - 1) * t * t * t - 1) + b;
};

jQuery(document).ready(function () {
  jQuery('a[href*="#"]').click(function () {
    let targetId = jQuery(this.hash);
    let scroll = $(window).scrollTop();

    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      let target = jQuery(this.hash);
      target = target.length && target || jQuery('[name=' + this.hash.slice(1) + ']');
      let headerH = $('#header').outerHeight(); //border-top含めて取得するため、outerHeightを使用
      let adjust = 0; // ターゲット先へスクロール移動

      if (target.length) {
        let targetOffset = target.offset().top - headerH - adjust;

        jQuery('html,body').animate({
          scrollTop: targetOffset
        }, 1200, 'swing');

        // ターゲット先がスライドパネルの場合はオープン
        if (target.next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {} else {
          target.next(".js-slideBox").find(".js-slideBtn").addClass('is-active');
          target.next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
        }

        return false;
      }
    }
  });
});

// 他ページからのsmoothScroll
$(function () {
  //現在のページURLのハッシュ部分を取得
  var hash = location.hash; //ハッシュ部分がある場合の条件分岐

  if (hash) {
    var headerH = $('#header').outerHeight(); //border-top含めて取得するため、outerHeightを使用
    $("html, body").stop().scrollTop(0); //処理を遅らせる

    setTimeout(function () {
      var target = $(hash),
          position = target.offset().top - headerH;
      $("html, body").animate({
        scrollTop: position
      }, 1200, "swing");
    }, "1");
  }
});


// slide btn
$(function(){
  $(".js-slideBtn").each(function(index,element) {
    $(element).click(function() {
      $(this).toggleClass('is-active');
      $(element).next(".js-slidePanel").slideToggle(300);
    });
  });

  // ハッシュがあるときはオープン
  if (window.location.hash.length>0) {
    if ($(window.location.hash).next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
    } else {
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").toggleClass('is-active');
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
    }
  }
});


// header scroll settings
$(window).on('load scroll resize', function() {  
  var winW = $(window).width();
  var winIW = window.innerWidth;  /* スクロールバーを含む幅 */
  var hd = $('#header');
  var contentTop = $('#maincontent').offset().top;
  var gnav = $('#gnavfix');
  var logo = $('#js-hdlogo');
  var gnavBtns = $('#ganvBtn');
  var scroll = $(window).scrollTop();

  if ( scroll < contentTop ) {
    hd.removeClass('js-isscroll');
    hd.addClass('js-noscroll');
  } else {
    hd.removeClass('js-noscroll');
    hd.addClass('js-isscroll');
  }
});


// バーガーメニュー開閉
$(function(){
  $("#js-drawerOpen").click(function() {
    if ($("#js-drawerOpen").hasClass('is-open')) {
      $("#js-drawerOpen").removeClass('is-open');
      $("#drawer").removeClass('is-open');
      $("#gnav").removeClass('is-open');
      $("#js-drawerOpen").addClass('is-close');
      $("#drawer").addClass('is-close');
      $("#gnav").addClass('is-close');
    } else {
      $("#js-drawerOpen").removeClass('is-close');
      $("#drawer").removeClass('is-close');
      $("#gnav").removeClass('is-close');
      $("#js-drawerOpen").addClass('is-open');
      $("#drawer").addClass('is-open');
      $("#gnav").addClass('is-open');
    }
  });
});

$(window).on('resize', function(){
  let winIW = window.innerWidth;

  //PC時は、ドロワーはis-open、is-closeクラスを削除する
  if ( winIW >= 768 ) {
    $("#js-drawerOpen").removeClass('is-open');
    $("#drawer").removeClass('is-open');
    $("#gnav").removeClass('is-open');
    $("#js-drawerOpen").removeClass('is-close');
    $("#drawer").removeClass('is-close');
    $("#gnav").removeClass('is-close');
  }
});

//メニュークリック
$(function(){
  $(".js-gnavItem").each(function(index,element) {
    $(element).click(function() {
      if ( $("#js-drawerOpen").hasClass('is-open') ) {
        $("#js-drawerOpen").removeClass('is-open');
        $("#drawer").removeClass('is-open');
        $("#gnav").removeClass('is-open');
      }
    });
  });

  // ハッシュがあるときはオープン
  if (window.location.hash.length>0) {
    if ($(window.location.hash).next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
    } else {
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").toggleClass('is-active');
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
    }
  }
});


$(window).on('load scroll resize', function(){  
  let winW    = $(window).width();
  let winIW = window.innerWidth;        //スクロールバーを含む幅 
  let winH    = $(window).height();  //ウィンドウの高さ
  let scroll  = $(window).scrollTop();
  let fadeinitem  = $('.js-fadeinitem');
  let fadeinbluritem  = $('.js-fadeinbluritem');
  let fadeinupitem  = $('.js-fadeinupitem');
  let slideupitem  = $('.js-slideupitem');
  let bluritem  = $('.js-bluritem');
  let bloomingitem  = $('.js-bloomingitem');
  let jumpupitem  = $('.js-jumpupitem');
  let revealtext  = $('.js-revealtext');
  let blurwhitem  = $('.js-blurwhitem');
  let charupitem  = $('.js-charupitem');
  let drawlineitem  = $('.js-drawlineitem');
  let fadeinblurmoverightitem  = $('.js-fadeinblurmoverightitem');
  let fadeinblurmoveupitem  = $('.js-fadeinblurmoveupitem');
  let popitem  = $('.js-popitem');

  //要素フェードイン
  fadeinitem.each(function(){
    let fiiTop = $(this).offset().top;

    // 要素が画面の上から80%(下から20%)に来た時に動く
    let fadeinitemPos = fiiTop - (winH * 0.8);

    if (scroll > fadeinitemPos) {
      $(this).addClass("js-fadein");
    }
  });

  //要素フェードイン&ブラー
  fadeinbluritem.each(function(){
    let fibiTop = $(this).offset().top;

    // 要素が画面の上から75%(下から25%)に来た時に動く
    let fadeinbluritemPos = fibiTop - (winH * 0.75);

    if (scroll > fadeinbluritemPos) {
      $(this).addClass("js-fadeinblur");
    }
  });

  //要素フェードイン&アップ
  fadeinupitem.each(function(){
    let fiuiTop = $(this).offset().top;

    // 要素が画面の上から75%(下から25%)に来た時に動く
    let fadeinupitemPos = fiuiTop - (winH * 0.75);

    if (scroll > fadeinupitemPos) {
      $(this).addClass("js-fadeinup");
    }
  });

  //要素スライドアップ
  slideupitem.each(function(){
    let suiTop = $(this).offset().top;

    // 要素が画面の上から75%(下から25%)に来た時に動く
    let slideupitemPos = suiTop - (winH * 0.75);

    if (scroll > slideupitemPos) {
      $(this).addClass("js-slideup");
    }
  });

  //要素ブラー
  bluritem.each(function(){
    let biTop = $(this).offset().top;

    // 要素が画面の上から75%(下から25%)に来た時に動く
    let bluritemPos = biTop - (winH * 0.75);

    if (scroll > bluritemPos) {
      $(this).addClass("js-blur");
    }
  });

  //要素ジャンプアップ
  jumpupitem.each(function(){
    let juiTop = $(this).offset().top;

    // 要素が画面の上から50%(下から50%)に来た時に動く
    let jumpupitemPos = juiTop - (winH * 0.5);

    if (scroll > jumpupitemPos) {
      $(this).addClass("js-jumpup");
    }
  });

  //要素に下線を引く
  drawlineitem.each(function(){
    let dliiTop = $(this).offset().top;

    // 要素が画面の上から75%(下から25%)に来た時に動く
    let drawlineitemPos = dliiTop - (winH * 0.75);

    if (scroll > drawlineitemPos) {
      $(this).addClass("js-drawline");
      
      let linkageitem = $(this).data('linkage');

      //2つの下線を連動させる場合、2つ目の下線を一定時間(750ms)後に引く(750msは、線を引くアニメーションのanimation-durationと同じ時間を設定する)
      if ( linkageitem != "" ) {
       setTimeout(function () {
          $('#' + linkageitem).addClass("js-drawline");
        }, "750");
      }
    }
  });

  //要素CharUp表示
  charupitem.each(function(){
    let cuTop = $(this).offset().top;

    // 要素が画面の上から80%(下から20%)に来た時に動く
    let charupitemPos = cuTop - (winH * 0.8);

    if (scroll > charupitemPos) {
      $(this).addClass("js-charup");
    }
  });

  //要素テキスト色Blooming
  bloomingitem.each(function(){
    let bmiTop = $(this).offset().top;

    // 要素が画面の上から90%(下から10%)に来た時に動く
    let bloomingitemPos = bmiTop - (winH * 0.9);

    if (scroll > bloomingitemPos) {
      $(this).addClass("js-isblooming");
    }
  });

  //要素フェードイン＆ブラー＆右に動く
  fadeinblurmoverightitem.each(function(){
    let fibmriTop = $(this).offset().top;

    // 要素が画面の上から75%(下から25%)に来た時に動く
    let fadeinblurmoverightitemPos = fibmriTop - (winH * 0.75);

    if (scroll > fadeinblurmoverightitemPos) {
      $(this).addClass("js-fadeinblurmoveright");
    }
  });

  //要素フェードイン＆ブラー＆上に動く
  fadeinblurmoveupitem.each(function(){
    let fibmuiTop = $(this).offset().top;

    // 要素が画面の上から75%(下から25%)に来た時に動く
    let fadeinblurmoveupitemPos = fibmuiTop - (winH * 0.75);

    if (scroll > fadeinblurmoveupitemPos) {
      $(this).addClass("js-fadeinblurmoveup");
    }
  });

  //要素POP
  popitem.each(function(){
    let piTop = $(this).offset().top;

    // 要素が画面の上から75%(下から25%)に来た時に動く
    let popitemPos = piTop - (winH * 0.75);

    if (scroll > popitemPos) {
      $(this).addClass("js-pop");
    }
  });
});


//fadein
$(window).scroll(function (){
  $('.js-fadeIn').each(function(){
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > elemPos - windowHeight - 30){
      $(this).addClass('js-scrollIn');
    }
  });
});


//1文字ずつ分割
const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
}

const splitText = (target) => {
  const splitElm = document.querySelectorAll(target);

  splitElm.forEach((el, index) => {
    let text = el.textContent;
    el.textContent = '';
    text = text.split('');
    let newText = '';
    text.forEach((t, index) => {
      newText += `<span data-random="${getRandomInt(1, 8)}">${t}</span>`;
    });
    el.insertAdjacentHTML('beforeend', newText);
  });
};


// 電話番号リンク
if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
  $(function() {
    $('.js-tellink').each(function() {
      var str = $(this).html();

      if ($(this).children().is('img')) {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      } else {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      }
    });
  });
}
